import { ILoanAccount } from "libs/models/CustomerProducts";
import { AccountStatus, AccountType, IBaseAccount } from "../../../../models/CustomerProducts/BaseAccount";
import { InvoiceDistributionFormatType } from "../../../../models/CustomerProducts/Invoice";
import { PaymentHolidayConsecutivePeriodConditionType } from "../../../../models/Content/Enums";

export const mockBasePrivateLoanAccountOne: IBaseAccount = {
  name: "Privatspar 1",
  nickname: "My Personal Privatspar 1",
  accountId: "4257557",
  accountStatus: AccountStatus.AccountTerminated,
  accountType: AccountType.PrivateLoan,
  isDisabled: false,
  displayNumber: "4257557",
  availableBalance: 5000,
  hasOverdueInvoice: true,
};

export const mockBasePrivateLoanAccountTwo: IBaseAccount = {
  name: "Privatspar 2",
  nickname: undefined,
  accountId: "1001001",
  accountStatus: AccountStatus.AccountMisManaged,
  accountType: AccountType.PrivateLoan,
  isDisabled: false,
  displayNumber: "1001001",
  availableBalance: -400,
};

export const mockBaseSalesFinanceLoanAccount: IBaseAccount = {
  name: "Sales Finance",
  nickname: undefined,
  accountId: "211049567",
  displayNumber: "211049567",
  accountStatus: AccountStatus.AccountOk,
  accountType: AccountType.SalesFinance,
  isDisabled: false,
  availableBalance: -9498,
  hasOverdueInvoice: true,
  isAmlFrozen: true,
};

export const mockPrivateLoanAccountOne: ILoanAccount = {
  ...mockBasePrivateLoanAccountOne,
  name: "Privatspar 1",
  mainDebtor: "Test Testsson 1",
  creditLimit: 5000,
  bookedBalance: -1700,
  accountInterest: 5.31,
  interestOffsetRate: 5.0,
  interestVariableRateType: "3-month Euribor",
  canTerminateAccount: true,
  canUpdateDueDay: true,
  canPromiseToPay: true,
  canPromiseToPayInvoiceInformation: {
    invoiceNumber: "123123",
    dueDate: "2024-01-13",
    invoiceAmount: 1000,
  },
  accountDetails: {
    bankAccount: "ABC-123",
    businessIdentificationCode: "BIC",
    description: "",
    receiverName: "Oi voi",
    referenceNumber: "123456",
    thirdPartyFee: 100,
  },
  paymentHolidayDetails: {
    elligibleFromDate: "",
    elligibleToDate: "",
    isRecurringPaymentHoliday: true,
    allowedPaymentHolidayMonths: 2,
    allowedPaymentHolidayMonthsPerYear: 2,
    consecutivePeriodCondition: PaymentHolidayConsecutivePeriodConditionType.ConsecutiveMonthsForbidden,
    currentPaymentHolidayMonths: [],
    canUpdateCurrentPaymentHolidayMonths: true,
  },
  canReduceInstallments: true,
  canRequestPaymentHoliday: true,
  invoiceDistributionFormat: InvoiceDistributionFormatType.SantanderInvoice,
  eInvoice: undefined,
};

export const mockPrivateLoanAccountTwo: ILoanAccount = {
  ...mockBasePrivateLoanAccountTwo,
  name: "Privatspar 2",
  mainDebtor: "Test Testsson 2",
  creditLimit: 125000,
  bookedBalance: -10000,
  accountInterest: 8.66,
  canTerminateAccount: false,
  canUpdateDueDay: false,
  canPromiseToPay: false,
  canPromiseToPayInvoiceInformation: undefined,
};

export const mockSalesFinanceLoanAccount: ILoanAccount = {
  ...mockBaseSalesFinanceLoanAccount,
  name: "Sales Finance",
  mainDebtor: "Gtjn Xyobåio Vgkcm Gvgsmh",
  creditLimit: 11398,
  bookedBalance: -9498,
  accountInterest: 0,
  canTerminateAccount: false,
  canUpdateDueDay: false,
  canPromiseToPay: false,
  canPromiseToPayInvoiceInformation: undefined,
  carInformation: {
    description: "Volva V70",
    license: "ABC-123",
  },
  canReduceInstallments: true,
};
