import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useFetchData, useStores } from "netbank-shared/src/hooks";
import { getAccountDisplayName, toLocaleString } from "netbank-shared/src/libs/utils";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { tx } from "netbank-shared/src/libs/i18n";
import { withRoot } from "../Root";
import styles from "../Page.scss";
import accordionStyle from "~views/shared/ContentArea/Blocks/AccordionBlock.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { NotFoundPage } from "../NotFound/NotFound";
import { Button, EmptyState, InfoText } from "~views/shared";
import { ProductPageSkeleton } from "../Skeletons/ProductPageSkeleton";
import { IEmptyState } from "netbank-shared/src/libs/models/Content/EmptyState";
import { AccordionName } from "netbank-shared/src/libs/models/Content/Enums";
import { AccordionContext } from "netbank-shared/src/contexts/accordionContext";
import { AccountStatus } from "netbank-shared/src/libs/models/CustomerProducts";
import editIcon from "~assets/pencil-outline.svg";
import { IContentAreaItem } from "netbank-shared/src/libs/models/Content/ContentAreaItem";
import { IModal } from "netbank-shared/src/libs/models/Content/Modal";
import { isProperty } from "netbank-shared/src/libs/models/Content/Property";

export interface LoanPageProps {
  location: Location;
}

const renderDisabledAccountContent = (blockedEmptyState: IEmptyState) => {
  return (
    <section>
      <div className={[accordionStyle.wrapper, accordionStyle.open].join(" ")}>
        <div className={accordionStyle.header}>
          {isProperty(blockedEmptyState.header) ? blockedEmptyState.header?.value : blockedEmptyState.header}
        </div>
        <div className={accordionStyle.content}>
          <div className={accordionStyle.verticalPadding}>
            <EmptyState data={blockedEmptyState} />
          </div>
        </div>
      </div>
    </section>
  );
};

const PrivateLoanPage = observer(({ location }: LoanPageProps) => {
  const { loanId } = useParams();
  const { contentStore, loanStore, uiStore } = useStores();

  const { currency, locale } = uiStore;

  const { currentAccount, overduePayments, duePayment } = loanStore;

  const isDisabled = currentAccount?.isDisabled;

  const loanTemplate = contentStore.currentPrivateLoanTemplatePage;

  const showAccountIsMisManagedBanner =
    currentAccount?.accountStatus === AccountStatus.AccountMisManaged && loanTemplate?.accountMisManagedText;
  const showAccountAccountIsTerminatedBanner =
    currentAccount?.accountStatus === AccountStatus.AccountTerminated && loanTemplate?.accountTerminatedText;
  const showAMLFrozenBanner = currentAccount?.isAmlFrozen && loanTemplate?.accountIsFrozenAMLText;
  const showHasOverdueInvoiceBanner = currentAccount?.hasOverdueInvoice && loanTemplate?.accountHasOverdueInvoiceText;

  const showAccountStatusBanners =
    !isDisabled &&
    (showAccountIsMisManagedBanner ||
      showAccountAccountIsTerminatedBanner ||
      showHasOverdueInvoiceBanner ||
      showAMLFrozenBanner);

  useFetchData({
    condition: !!loanId && !loanStore.loadingAccount,
    callback: () => {
      loanStore.getLoanAccount(loanId);
    },
    cleanup: () => {
      loanStore.resetLoanPayments();
      loanStore.resetCurrentAccount();
    },
  });

  useFetchData({
    condition: !!loanId && !loanStore.loadingOverduePayments && !loanStore.overduePayments,
    callback: () => {
      loanStore.getOverduePayments(loanId);
    },
  });

  useFetchData({
    condition: !!loanId && !loanStore.loadingDuePayment && duePayment === undefined,
    callback: () => {
      loanStore.getDuePayment(loanId);
    },
  });

  useFetchData({
    callback: () => {
      if (loanStore.currentAccountId !== loanId && !!loanId) loanStore.currentAccountId = loanId;
      if (currentAccount?.accountId === loanId) contentStore.getPrivateLoanTemplate();
    },
    deps: [location, currentAccount],
  });

  useEffect(() => {
    if (uiStore.logoAnimationLoaded) {
      uiStore.setLoading(false);
    }
  }, [uiStore.logoAnimationLoaded]);

  if (uiStore.loading) return null;

  if (contentStore.fetchingTemplatePage || loanStore.loadingAccount) {
    return <ProductPageSkeleton />;
  }

  if (!currentAccount) {
    return <NotFoundPage />;
  }

  const expandedAccordions: AccordionName[] = [];
  if (overduePayments && overduePayments.length > 0) {
    expandedAccordions.push(AccordionName.AccordionOverduePaymentsBlock);
  }
  if (duePayment) {
    expandedAccordions.push(AccordionName.AccordionActiveInvoiceBlock);
  }

  const renderModal = (modalContent?: IContentAreaItem[]) => {
    const modal: IModal = {
      content: modalContent || [],
    };

    uiStore.setModal(modal);
  };

  return (
    <div className={styles.wrapper}>
      <section className={styles.headerWrapper}>
        <div>
          <h1
            style={{
              display: "inline-flex",
            }}
          >
            {getAccountDisplayName(loanStore.currentAccount)}
          </h1>
          {contentStore.isSetAccountNameEnabled && (
            <Button
              className={styles.boxWrapper}
              onClick={() => {
                renderModal(loanTemplate?.setAccountNameModalContent);
              }}
            >
              <div className={styles.box}>
                <img src={editIcon} alt="edit-icon" />
              </div>
            </Button>
          )}
          <p>
            <span className={styles.bold}>{loanTemplate?.subHeading || "N°:"}</span>
            {` ${currentAccount.displayNumber}`}
          </p>
          {loanTemplate?.accountTypeSubHeadingLabel && (
            <p>
              <span className={styles.bold}>{loanTemplate.accountTypeSubHeadingLabel}</span>
              {` ${currentAccount.name}`}
            </p>
          )}
        </div>
      </section>
      {!isDisabled && (
        <section>
          <div className={styles.templateHeader}>
            {!uiStore.isMobile && (
              <div
                className={styles.image}
                style={{
                  backgroundImage: `url(${loanTemplate?.image?.url})`,
                }}
              />
            )}
            <div className={styles.templateContent}>
              <div className={styles.availableBalance}>
                <div>
                  <span className={styles.templateTitle}>{loanTemplate?.actualAmount}</span>
                  <h3>{toLocaleString(currentAccount.bookedBalance, currency, locale)}</h3>
                </div>
                {uiStore.isMobile && (
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${loanTemplate?.image?.url})`,
                    }}
                  />
                )}
              </div>
              <div className={styles.flexBetween}>
                <div>
                  <span className={styles.templateTitle}>{loanTemplate?.interest || tx("misc.interest")}</span>
                  <h4>{`${toLocaleString(currentAccount.accountInterest || 0, undefined, locale, 2)} %`}</h4>
                </div>
                <div>
                  <span className={styles.templateTitle}>{loanTemplate?.totalAmount}</span>
                  <h4>{toLocaleString(currentAccount.creditLimit, currency, locale)}</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {showAccountStatusBanners && (
        <section className={styles.accountStatusContainer}>
          {showAccountAccountIsTerminatedBanner && (
            <InfoText text={loanTemplate?.accountTerminatedText} level={NotificationLevel.error} />
          )}
          {showAccountIsMisManagedBanner && (
            <InfoText text={loanTemplate?.accountMisManagedText} level={NotificationLevel.error} />
          )}
          {showHasOverdueInvoiceBanner && (
            <InfoText
              textWithQueryLink={loanTemplate.accountHasOverdueInvoiceText}
              level={NotificationLevel.information}
              accountNumber={currentAccount.accountId}
            />
          )}
          {showAMLFrozenBanner && (
            <InfoText text={loanTemplate?.accountIsFrozenAMLText} level={NotificationLevel.error} />
          )}
        </section>
      )}

      {!isDisabled && (
        <AccordionContext.Provider value={{ expandedAccordions }}>
          <ContentArea page={loanTemplate} />
        </AccordionContext.Provider>
      )}
      {isDisabled &&
        loanTemplate?.accountBlockedEmptyState?.[0] &&
        renderDisabledAccountContent(loanTemplate.accountBlockedEmptyState[0])}
    </div>
  );
});

export default withRoot(PrivateLoanPage);
